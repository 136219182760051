/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from 'components/Globals/Base/Button';
import Link from 'components/Globals/Base/Link';
import MyAccount from 'components/MyAccount';
import { Logo } from 'icons/Logo';
import { UserContext } from 'providers/UserProvider';
import { useContext } from 'react';
import showLogin from 'services/Piano/showLogin';
import { trackEvent } from 'services/Gtm/functions';
import { Microsites } from 'constants/constants';
import Placeholder from 'components/Placeholder';
import { Bars } from 'icons/Bars';
import { DrawerToggleButton } from 'components/Header/HeaderStyles';
import * as S from './DesktopNavigationBarStyles';

export interface NavigationBarProps {
  domainLogo: string | null;
  subDomain: string;
  logoHref: string;
  isSubscribePage: boolean;
}
// eslint-disable-next-line react/no-danger, max-len
const renderDomainLogo = (html?: string | null) => html && <div dangerouslySetInnerHTML={{ __html: html }} />;

export const showInstreamAd = (micrositeDomain: string | undefined) => {
  googletag
    .defineSlot(
      `/21809533738/${Microsites.Well === micrositeDomain ? 'fortune.well' : 'fortune'}/search/advancedsearch/instream`,
      [
        [728, 90],
        [300, 250],
        [336, 280],
      ],
      'InstantSearch_InStream0',
    )
    ?.addService(googletag.pubads());
  googletag.pubads().refresh();
  document.getElementById('queryly_query')?.addEventListener('keydown', () => {
    googletag.pubads().refresh();
  });
};

const DesktopNavigationBar = (props: NavigationBarProps) => {
  const { domainLogo, subDomain, logoHref, isSubscribePage } = props;
  const { userState: user } = useContext(UserContext);

  const shouldRenderMyAccount = true;

  const shouldRenderSearch =
    ['own', 'well', 'europe', 'recommends', 'education'].includes(subDomain) && !isSubscribePage;

  const displayLogo = ['crypto', 'well'].includes(subDomain);

  const trackingData = {
    contentPlacementCD: 'search hero',
    eventAction: 'search hero experiment',
    eventCategory: 'search',
  };
  const burgerTrackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: 'Global Side navigation toggle',
    eventCategory: 'navigation',
    eventLabel: 'open',
  };

  const trackClick = (event: string) => {
    trackEvent({ ...trackingData, eventLabel: event });
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.currentTarget.click();
    }
  };

  return (
    <S.NavigationContent
      className={subDomain}
      key='desktop'
    >
      <S.NavigationLeft>
        <DrawerToggleButton
          htmlFor='drawer-toggle'
          className='hamburger-toggle'
          onClick={() => {
            if (trackEvent && burgerTrackingData) trackEvent(burgerTrackingData);
          }}
          onKeyDown={handleKeyDown}
          aria-label='Open menu'
          data-cy='burger-button'
          tabIndex={0}
        >
          <Bars isMobile={false} />
        </DrawerToggleButton>
        {shouldRenderSearch && (
          <S.NavigationSearchWrapper
            className={subDomain}
            tabIndex={0}
            role='button'
            onClick={() => {
              showInstreamAd(subDomain);
              trackClick('Search');
            }}
            onKeyDown={() => trackClick('Search')}
            data-cy='searchButton'
          >
            <label htmlFor='queryly_toggle'>SEARCH</label>
          </S.NavigationSearchWrapper>
        )}
      </S.NavigationLeft>
      <S.NavigationCenter className={subDomain}>
        <Link
          href={logoHref}
          ariaLabel='Homepage'
          dataCy='logo'
        >
          {(displayLogo && renderDomainLogo(domainLogo)) || <Logo />}
        </Link>
      </S.NavigationCenter>
      {shouldRenderMyAccount && (
        <S.NavigationRight className={subDomain}>
          {user.isLoggedIn === null && user.hasAccess === null && (
            <Placeholder
              size={{ height: 40, width: 200 }}
              className='account-placeholder'
            />
          )}

          {user.hasAccess === false && !isSubscribePage && (
            <Link
              className='subscribe-link'
              href='/subscribe/'
              dataCy='subscribeBtnDesktop'
              type='accentButton'
              size={{ default: 'xsmall' }}
              hasRightArrow={false}
            >
              SUBSCRIBE NOW
            </Link>
          )}
          {user.isLoggedIn === false && (
            <Button
              className='sign-in-button'
              onClick={showLogin}
              dataCy='signinBtnDesktop'
              variant='tertiary'
              hasRightArrow={false}
            >
              SIGN IN
            </Button>
          )}
          {user.isLoggedIn && <MyAccount className={`${subDomain} account-menu`} />}
        </S.NavigationRight>
      )}
    </S.NavigationContent>
  );
};

export default DesktopNavigationBar;
