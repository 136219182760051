import { Author } from 'interfaces/content/Authors';
import { MediaItem } from 'interfaces/content/Media';
import { Faq } from 'interfaces/components/Faq';
import { BypassPaywallResponse } from 'interfaces/BypassPaywall';

export interface ContentCategory {
  name: string;
  premiumCategoryId?: number;
  primaryTopicId?: number;
}

export interface PrimaryPostDetails {
  name: string;
  primaryPostTagId?: number;
  primarySectionId?: number;
  link: string;
  uri: string;
}

export interface Section {
  name: string;
  sectionId: number;
  slug: string;
}

export enum PageType {
  NO_TYPE = '',
  ARTICLE = 'article',
  ARTICLE_V2 = 'article-v2',
  BRANDED_ARTICLE = 'branded-article',
  LONGFORM = 'longform',
  COMPANY = 'company',
  EARNINGS_REPORT = 'earnings_report',
  COMPANY_V2 = 'company-v2',
  FRANCHISE_LIST = 'franchise-list',
  FRANCHISE_LIST_SEARCH = 'franchise-list-search',
  FRANCHISE_LIST_ITEM = 'franchise-list-item',
  FRANCHISE_LIST_ITEM_COMPANY = 'franchise-list-item-company',
  FRANCHISE = 'franchise',
  PAGE = 'page',
  MAIN_HOMEPAGE = 'mainHomepage',
  HOMEPAGE = 'homepage',
  SECTION = 'section',
  SPONSORED_PAGE = 'sponsored-page',
  STATIC_PAGE = 'static-page',
  SUBSCRIPTIONS = 'subscriptions',
  TOPIC = 'topic',
  TAG = 'tag',
  MAGAZINE = 'magazine',
  PACKAGE = 'package',
  PACKAGES = 'packages',
  AUTHOR = 'author',
  RANKINGS = 'rankings',
  HUB = 'hub',
  GUIDE_HUB = 'guide hub',
  GUIDE = 'guide',
  EVERGREEN = 'evergreen',
  LATEST = 'latest',
  PERSON = 'person',
  EDU_CHILD_RANKING_LIST = 'edu-child-rankings-list',
  EDU_RANKING_LIST = 'edu-rankings-list',
  EDU_SCHOOL = 'edu-school',
  EDU_CATEGORY = 'edu-category',
  SITEMAP = 'sitemap',
  SITEMAP_HOME = 'sitemap-home',
  INTERACTIVE_TOOL = 'interactive-tool',
}

/**
 * This properties are expected on every page component
 * to support integrations
 */
export interface Page {
  adTags: { name: string; slug: string }[];
  authors: Author[];
  authorNames: Array<string>;
  canonicalUrl: string;
  channel: string;
  databaseId: number;
  dateGmt: string;
  description: string;
  faq: Faq[];
  image: MediaItem | string | null;
  imageTwitter?: MediaItem | string | null;
  keywords: string;
  link: string;
  metaTitle: string;
  modifiedGmt: string;
  pageId?: number;
  pageType: PageType;
  premiumCategory: ContentCategory;
  primarySection: PrimaryPostDetails;
  socialDescription: string;
  socialTitle: string;
  sectionNames: Array<string>;
  sections: Section[];
  tagNames: Array<string>;
  tags: {
    name: string;
    tagId: number;
  }[];
  thumbnailUrl: string;
  title: string;
  titlePrefix?: string;
  topicNames: Array<string>;
  topics: { name: string; slug: string }[];
  url: string;
  platformCD: string;
  featuredMediaType?: string;
  relatedVideoType?: string;
  widgetCss?: string | null;
  prefixedDatabaseId?: string | null;
}

/**
 * This properties are specific to Longform & Article
 * posts
 */

export interface PostSettings {
  enableSubscriptionPlea: boolean;
  showSurvey: boolean;
  mediaItem: MediaItem;
  postSettingsDek: string;
  subscriptionPleaDesign: string;
  subscriptionPleaPosition: number;
  subscriptionPleaTextLined: string;
  bylineCommentary?: string;
}

export interface Post extends Page {
  audienceDek: string;
  bypassPaywall: BypassPaywallResponse;
  content: string;
  dek: string;
  id: string;
  postId: number;
  postSettings: PostSettings;
  primaryPostTag: PrimaryPostDetails;
  primaryTopic: ContentCategory;
  showModifiedTimestamp: boolean;
}

export interface Logo {
  altText: string;
  caption: string;
  databaseId: string;
  mediaItemUrl: string;
}

export interface BrandedArticle extends Post {
  templateType: 'standard' | 'hero_video' | 'custom_iframe';
  partnerLogo: Logo;
  paidContentLabel: string;
  postBypassLinkEnabled: string;
  postBypassLinkUnique: string;
  relatedVideoType: string;
  relatedVideoId: string;
  featuredMediaType: string;
  fortuneLogo: Logo;
  brandedArticleId: number;
  vodVideoId: string;
  image: string;
  imageCaption: string;
  imageCredit: string;
  slug: string;
  uri: string;
  adTags: {
    name: string;
    slug: string;
  }[];
  partnerLogoLink: string;
  excerpt: string;
}

export interface LongformSlug {
  slug: string;
  title: string;
  uri: string;
  date: string;
}

export interface HeroBuilder {
  fullBleedMediaLayerType: string;
  fullBleedVideoLayer: string | null;
  fullBleedVideoLayerVodVideoId: string | null;
  heroLayout: 'landscape' | 'portrait' | 'full_bleed';
  landscapeMediaLayerType: string;
  landscapeVideoLayer: string | null;
  landscapeVideoLayerVodVideoId: string | null;
  portraitMediaLayerType: string;
}

export interface Longform extends Post {
  featuredMediaType: string;
  heroBuilder: HeroBuilder;
  image: MediaItem;
}

export interface Person {
  education: string;
  image: string;
  name: string;
  position: string;
}

export interface PersonPage extends Page {
  person: Person;
}

export interface PageSlug {
  slug: string;
}

export interface SimplePage extends Page, PageSlug {
  content: string;
  contentType?: string;
  siteName?: string;
  destination: 'main' | 'recommends';
}
