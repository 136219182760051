import CustomLink from 'components/Globals/Base/Link';
import styled, { css } from 'styled-components';
import { breakpoint, navMargin } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const NavigationList = styled.ul<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  gap: 16px;

  & > li:last-child > ul { 
    right: 0;
    left: unset;

    @media (${breakpoint.lgMin}) {
      right: unset;
      left: 0;  
    }
  }
  @media (${breakpoint.mdMax}) {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 16px;
    white-space: nowrap;
    scrollbar-width: none;
    overflow: scroll hidden;
  }
`;

export const NavigationWrapper = styled.div<{ theme: Theme }>`
  padding: 16px;
  box-shadow: 0px 25px 20px -20px ${getColor('surfaceBlack')}10;
  &:not(.no-margin) {
    ${navMargin(false)};
  }
  
`;

export const SubMenuBridge = styled.div`
  position: absolute;
  width: 100%;
  height: 18px;
  background: transparent;
`;

export const itemBorder = css<{ theme: Theme; $color: string }>`
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -1px;
  height: 2px;
  background: ${getColor('borderAccentSecondary')};
`;

export const NavigationItem = styled.li<{
  $color: string;
  theme: Theme;
}>`
  position: relative;
  display: inline-block;
  padding: 0 8px;
  
  @media (${breakpoint.lgMin}) {
    &:hover, &:focus-within {
      > ul {
        display: flex;  
        left: 0;
      }
    }
  }

  &:hover > a {
     &::after {
      ${itemBorder}
    }
  }
`;

export const NavigationStyledLink = styled(CustomLink)<{
  theme: Theme;
  $color: string;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  
  &.selected {
    &::after {
      ${itemBorder}
    }
  }
`;
